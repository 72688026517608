import styled from "styled-components";
import { Copyright } from "../Copyright";
import { colours } from "../../styles/colours";

import areteLogo from "../../media/arete-logo.png";
import ContactDetails from "../ContactDetails";

const FooterContainer = styled.footer``;

const ContactInfo = styled.div`
  padding: 60px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 20px;
  background-color: ${colours.lightGrey};
`;

export const Footer = () => {
  return (
    <FooterContainer>
      <ContactInfo>
        <div style={{ padding: "0 15px" }}>
          <div>
            <img src={areteLogo} alt="Arete Logo" height={80} />
          </div>
        </div>
        <ContactDetails />
      </ContactInfo>
      <Copyright />
    </FooterContainer>
  );
};
