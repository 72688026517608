import styled from "styled-components";

export const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    > img {
      border-radius: 5px;
    }
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
