import styled from "styled-components";
import { ImageTitleBanner } from "../../components/ImageTitleBanner";
import welloPointJetty from "../../media/wellington-point-jetty86kb20pQual.webp";
import { Helmet } from "react-helmet";
import {
  ArrowDownload16Regular,
  Camera28Filled,
  ContactCard28Filled,
  Home28Filled,
  ThumbLike28Filled,
} from "@fluentui/react-icons";
import { colours } from "../../styles/colours";
import laptopStock from "../../media/laptopWithPropertyMe.jpg";
import keysStockImage from "../../media/keys.jpg";
import { StarList, WhatWeDo } from "./WhatWeDo";
import { PolicySection } from "./PolicySection";
import {
  REVIEWS,
  ReviewBody,
  ReviewCard,
  ReviewLocation,
  ReviewName,
} from "./reviews/Review";
import { TwoColumns } from "../../components/TwoColumns";

const Content = styled.div`
  max-width: 1200px;
  padding: 30px;
  @media (max-width: 1400px) {
    max-width: 1000px;
    padding: 5px;
  }
`;

const PropertyManagementPage = styled.article`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
`;

const NoIconList = styled.ul`
  list-style-type: none;
`;

const IconList = styled(NoIconList)`
  > li {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    > div {
      > svg {
        background-color: ${colours.primary};
        color: white;
        padding: 20px;
        border-radius: 50%;
      }
    }
  }
`;

const DownloadLink = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  > svg {
    background-color: ${colours.primary};
    color: white;
    padding: 4px;
    border-radius: 50%;
  }
`;

const LinkList = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export function PropertyManagement() {
  return (
    <PropertyManagementPage>
      <Helmet title="Property management" />
      <ImageTitleBanner
        image={welloPointJetty}
        title={"Property Management"}
        subtitle={
          "Arete Property Management, a subsidiary of Arete Real Estate, manages rental tenancies throughout the Redlands"
        }
      />
      <Content>
        <section>
          <p>
            Investing in property should be rewarding, stress free and
            profitable. That’s why we’ve developed a unique system for the
            leasing and management of residential property that maximizes your
            property’s potential. We have developed processes that will deliver
            you the comfort and confidence you deserve as an investor and
            property owner.
          </p>
        </section>

        <section>
          <h2>WHY YOU SHOULD CHOOSE US</h2>
          <p>
            Arete Property Management, is a boutique real estate company located
            in the Redlands that saves your time and effort when it comes to
            Property Management services for you and your tenants. Since 2014,
            we have forged relationships and partnerships that you can have
            confidence in. Our team aims to exceed your expectations. We want
            you to feel confident that you are working with an Agency that has
            your best interests in mind.
          </p>
        </section>

        <WhatWeDo />

        <section>
          <h2>ADVERTISING & TENANT SELECTION</h2>
          <TwoColumns>
            <div>
              <h3>TENANCY SELECTION</h3>
              <p>
                Finding the right tenant can be time consuming and stressful.
                Arete Property Management is dedicated to delivering a
                personalised experience for both the tenant and the landlord and
                provide both parties with the same commitment, professionalism
                and attention to detail. Arete Property Management’s detailed
                screening process aims to establish that tenants are able to
                meet the responsibilities required by the General Tenancy
                Agreement.
              </p>
              <p>
                Our selection process is in accordance with laws relating to
                discrimination and privacy. Arete Property Management is a
                member of TICA – Tenancy Information Centre of Australia – and
                can source current information on all prospective tenants.
              </p>
              <p>
                All prospective tenants are required to inspect the property
                prior to signing an agreement. We encourage landlords to be
                involved in the selection process and will keep you informed at
                every stage of the process.
              </p>
            </div>

            <div>
              <h3>ARETE REAL ESTATE WILL:</h3>
              <IconList>
                {/* TODO: add icons */}
                <li>
                  <div>
                    <ContactCard28Filled />
                  </div>
                  <span>Install a sign on your property</span>
                </li>
                <li>
                  <div>
                    <Camera28Filled />
                  </div>
                  <span>
                    Provide quality photography showcasing your properties’
                    unique features
                  </span>
                </li>
                <li>
                  <div>
                    <Home28Filled />
                  </div>
                  <span>
                    List your property on <i>RealEstate.com.au</i>
                  </span>
                </li>
                <li>
                  <div>
                    <ThumbLike28Filled />
                  </div>
                  <span>
                    Advertise your property on <i>AreteRealEstate.com.au</i> and
                    the Arete Real Estate Facebook page with promoted posts
                  </span>
                </li>
              </IconList>
            </div>
          </TwoColumns>
        </section>
        <section>
          <TwoColumns>
            <div>
              <h2>INSPECTIONS & INSPECTION REPORTS</h2>
              <p>
                An Entry Condition Report is provided to landlords prior to
                occupancy and an Exit Condition Report at expiry of the tenancy.
                This report includes colour photography and a full inventory. We
                will conduct routine inspections every 3 months and a report
                with photos are sent to the landlord.
              </p>
            </div>
            <div>
              <img
                src={laptopStock}
                style={{ width: "90%" }}
                alt="laptop with property software running"
              />
            </div>
          </TwoColumns>
        </section>
        <PolicySection />
        <section>
          <TwoColumns>
            <div>
              <h2>LETTING FEE</h2>
              <p>
                A fee equal to one (1) week’s rent is charged for the following
                services:
              </p>
              <ul>
                <li>Showing the property to prospective tenants</li>
                <li>Access to defaulting tenant database</li>
                <li>Reference checks and application verification</li>
                <li>Tenant selection</li>
                <li>100 point identification checklist</li>
                <li>Preparation of Lease and Bond lodgment forms</li>
                <li>Preparation of tenants induction pack</li>
                <li>Remittance of bond to Bond Authority</li>
                <li>Entry Condition Report including photographs </li>
              </ul>
            </div>
            <div>
              <h2>MANAGEMENT FEE</h2>
              <p>
                Competitive property management fee; based on your requirements.
              </p>
              <img
                src={keysStockImage}
                alt="keys on table"
                style={{ width: "90%" }}
              />
            </div>
          </TwoColumns>
        </section>

        <section>
          <h2>TESTIMONIALS</h2>
          <StarList>
            {REVIEWS.map((review) => (
              <div key={review.body}>
                <ReviewCard key={review.name}>
                  <ReviewBody>
                    {'"'}
                    {review.body}
                    {'"'}
                  </ReviewBody>
                  <div>
                    <ReviewName>{review.name}</ReviewName>
                    {", "}
                    <ReviewLocation>
                      {review.type}, <em>{review.location}</em>
                    </ReviewLocation>
                  </div>
                </ReviewCard>
              </div>
            ))}
          </StarList>
        </section>

        <section>
          <h2>Downloads</h2>
          <LinkList>
            <DownloadLink
              download="Arete-property-management-information-pack.pdf"
              href={require("./property-management-information-pack.pdf")}
            >
              <ArrowDownload16Regular />
              Property management information pack
            </DownloadLink>
            <DownloadLink
              download="Arete-investor-information-pack.pdf"
              href={require("./investor-information-pack.pdf")}
            >
              <ArrowDownload16Regular />
              Investor information pack
            </DownloadLink>
            <DownloadLink
              download="Arete-owner-access-brochure.pdf"
              href={require("./Owner-Access-Brochure-Arete.pdf")}
            >
              <ArrowDownload16Regular />
              PropertyMe owner access brochure
            </DownloadLink>
            <DownloadLink
              download="Arete-tenant-pocket-guide.pdf"
              href={require("./Tenant-pocket-guide.pdf")}
            >
              <ArrowDownload16Regular />
              Tenant pocket guide
            </DownloadLink>
          </LinkList>
        </section>
      </Content>
      <br />
      <br />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
      />
      <link
        rel="stylesheet"
        type="text/css"
        href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
      />
    </PropertyManagementPage>
  );
}
