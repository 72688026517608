import { Star48Regular } from "@fluentui/react-icons";
import { ReactNode } from "react";
import styled from "styled-components";
import { colours } from "../../styles/colours";

const NoIconList = styled.ul`
  list-style-type: none;
`;

export const StarList = styled(NoIconList)`
  list-style-type: none;
  padding: 30px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  // breakpoint for smaller screens
  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 900px) {
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 400px) {
    grid-template-columns: repeat(1, 1fr);
  }
  align-items: start;
  justify-items: center;
`;

interface Props {
  children: ReactNode;
}

const StarListItem = (props: Props) => {
  const Li = styled.li`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2vw;
    text-align: center;
    @media (max-width: 600px) {
      margin: 5px;
    }
  `;
  return (
    <Li>
      <Star48Regular primaryFill={colours.primary} style={{ margin: "20px" }} />
      {props.children}
    </Li>
  );
};

export function WhatWeDo() {
  return (
    <section>
      <h2>WHAT WE DO?</h2>
      <StarList>
        <StarListItem>
          Prepare Form 6 Appointment to Act as Property Management
        </StarListItem>
        <StarListItem>
          Renewal of Form 6 at the end of each Tenancy
        </StarListItem>
        <StarListItem>
          Detailed Entry Condition Report inspection of your investment property
        </StarListItem>
        <StarListItem>
          Rental Market Appraisal, based on the current market
        </StarListItem>
        <StarListItem>
          Preparation of all hard copy tenant and landlord documentation
        </StarListItem>
        <StarListItem>
          Advertise your property on RealEstate.com.au, Facebook and
          AreteRealEstate.com.au
        </StarListItem>
        <StarListItem>
          Thorough tenancy checks of all applicants prior to submitting for
          approval
        </StarListItem>
        <StarListItem>
          Provide all relevant QLD Tenancy Act documentation
        </StarListItem>
        <StarListItem>
          Photographic documentation of your property before, during and after
          each Tenancy
        </StarListItem>
        <StarListItem>
          Disbursement of rent monies, monthly into your nominated bank account
        </StarListItem>
        <StarListItem>
          Liaising directly with Tenants regarding inspections
        </StarListItem>
        <StarListItem>
          Coordinate professional bond clean with tenant at their expense
        </StarListItem>
        <StarListItem>
          Service of required notices and collection processes
        </StarListItem>
        <StarListItem>Lease renewal process</StarListItem>
        <StarListItem>Rent Reviews</StarListItem>
        <StarListItem>
          Detailed inspection and Exit Condition Reports, vacate process and
          bond disbursement
        </StarListItem>
      </StarList>
    </section>
  );
}
