import styled from "styled-components";
import { colours } from "../../styles/colours";
import { CONTACT_EMAIL } from "../../constants";

const Form = styled.form`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;

  > input,
  textarea {
    border-color: #f8f8f8;
    background-color: #f8f8f8;
    border: none;
    width: 100%;
    padding: 15px;
    width: 300px;
  }
  > input[type="submit"] {
    width: 330px;
    background-color: ${colours.primary};
    color: white;
    transition: background-color 0.2s ease-in-out;
    border-radius: 3px;
    :hover {
      cursor: pointer;
      background-color: #de6739;
    }
  }
`;

export default function ContactForm() {
  return (
    <Form action={`mailto:${CONTACT_EMAIL}`} method="get">
      <input type="text" name="subject" placeholder="Subject" required />
      <input type="email" name="email" placeholder="Your Email" required />
      <textarea
        required
        name="body"
        rows={5}
        placeholder="Your Message"
      ></textarea>
      <input type="submit" value="Send" />
    </Form>
  );
}
