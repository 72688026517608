import styled from "styled-components";
import { font } from "../styles/font";
import dotsOverlay from "../media/dots-mini-strong.png";

const BANNER_HEIGHT = 484;
const Banner = styled.title<{ height: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ height }) => height}px;
  width: 100vw;
  background-color: #f5f5f5;
`;

const Title = styled.h1`
  font-size: ${font.title.fontSize};
  @media (max-width: 800px) {
    font-size: 40px;
  }
  color: ${font.title.color};
  font-family: ${font.title.fontFamily};
  text-align: center;
  z-index: 2;
  font-weight: 300;
  margin: 0;
`;

const Subtitle = styled.p`
  font-size: ${font.subtitle.fontSize};
  @media (max-width: 800px) {
    /* font-size: 40px; */
  }
  color: ${font.subtitle.color};
  font-family: ${font.subtitle.fontFamily};
  text-align: center;
  z-index: 2;
  font-weight: 300;
  margin: 0;
`;

const ImageBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${BANNER_HEIGHT}px;
  width: 100vw;
  > img {
    object-fit: cover;
    width: 100%;
  }
  z-index: 0;
`;
const ImageOverlay = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;

  background-image: url(${dotsOverlay});
  background-size: 60;
  background-color: #0000006f;
  height: ${BANNER_HEIGHT}px;
`;

const TextContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 10px;
`;

interface ImageTitleBannerProps {
  image: string;
  title: string;
  height?: number;
  subtitle?: string;
}

export const ImageTitleBanner = ({
  image,
  title,
  subtitle,
  height = BANNER_HEIGHT,
}: ImageTitleBannerProps) => {
  return (
    <Banner height={height}>
      <ImageBanner>
        <img src={image} alt={"Two people"} height={height} />
      </ImageBanner>
      <ImageOverlay />
      <TextContainer style={{ zIndex: 1 }}>
        <Title>{title.toUpperCase()}</Title>
        <Subtitle>{subtitle}</Subtitle>
      </TextContainer>
    </Banner>
  );
};
