import { Helmet } from "react-helmet";
import frontImage from "../../media/36adina-front.jpeg";
import { ImageTitleBanner } from "../../components/ImageTitleBanner";
import { Article } from "../../components/Article";
import {
  Bed24Filled,
  Showerhead24Filled,
  VehicleCar24Filled,
} from "@fluentui/react-icons";
import styled from "styled-components";
import { TwoColumns } from "../../components/TwoColumns";

const PropertyDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin: 0 5px;
  }
`;

const PropertyCost = styled.span`
  font-size: 2rem;
  font-weight: 600;
  margin: 10px 0;
`;

const PropertyLocation = styled.span`
  font-size: 1.5rem;
  font-weight: 600;
  margin: 10px 0;
`;

/**
 * real estate listing page for 36 Edina Street, Thornlands, Qld 4164
 */
export const EdinaStreet = () => {
  return (
    <>
      <Helmet title="36 Edina Street" />
      <ImageTitleBanner
        image={frontImage}
        title={"36/30-46 Edina Street, Thornlands, Qld 4164"}
      />
      <section>
        <Article>
          <div>
            <TwoColumns>
              <div>
                <PropertyLocation>
                  36/30-46 Edina Street, Thornlands, Qld 4164
                </PropertyLocation>
                <PropertyDetails>
                  <Bed24Filled /> 3
                  <Showerhead24Filled /> 2
                  <VehicleCar24Filled /> 2
                </PropertyDetails>
              </div>
              <div>
                <PropertyCost>$650,000</PropertyCost>
              </div>
            </TwoColumns>
            <h2>Property Details</h2>

            <div>
              <p>
                Don&apos;t miss out on this rare opportunity to own a beautiful,
                brand new 3 bedroom, 2 bathroom standalone villa in a private
                complex of only 51 Villas. With only 3 of these stunning homes
                remaining, this is your chance to secure your dream home in a
                sought-after location.
              </p>
              <h2>Key Features:</h2>
              <ul>
                <li>Spacious 3 bedroom, 2 bathroom standalone villa;</li>
                <li>
                  Ducted air conditioning throughout for year-round comfort;
                </li>
                <li>Hybrid wood floors for a modern and luxurious feel;</li>
                <li>Upgraded kitchen appliances for the aspiring chef;</li>
                <li>Gated complex for added security and peace of mind;</li>
                <li>
                  Heated swimming pool, perfect for relaxation and entertaining;
                </li>
                <li>
                  Al fresco kitchen and BBQ area, ideal for outdoor dining and
                  gatherings;
                </li>
                <li>
                  Well-equipped gym to maintain an active and healthy lifestyle;
                  and
                </li>
                <li>Double garage for secure parking and extra storage.</li>
              </ul>
              <h2>Ideal for:</h2>
              <ul>
                <li>Downsizers looking for a low-maintenance luxury villa;</li>
                <li>
                  First home buyers ready to embark on their homeownership
                  journey;
                </li>
                <li>
                  Investors seeking excellent rental potential with full
                  depreciation available;
                </li>
                <li>
                  Low Body Corporate fees, ensuring cost-effective living.
                </li>
              </ul>
              <h2>Convenient Location:</h2>
              <ul>
                <li>
                  Close proximity to all amenities, including shops, medical
                  facilities, hospitals, schools and more;
                </li>
                <li>
                  Easy access to the stunning Moreton Bay for outdoor
                  enthusiasts;
                </li>
                <li>
                  Well-connected to public transport options for commuting
                  convenience;
                </li>
                <li>
                  Surrounded by a variety of cafes and restaurants, perfect for
                  dining out and socializing.
                </li>
              </ul>
              <p>
                Additional Details:
                <ul>
                  <li>6 year builder&apos;s warranty for peace of mind; and</li>
                  <li>
                    Luxury artificial grass for a low maintenance outdoor space.
                  </li>
                </ul>
              </p>
              <p>
                Don&apos;t delay, seize this opportunity to own a brand new,
                completed 3 bedroom standalone villa in a private complex.
                Contact Karen on 0438 695 744 to arrange a private viewing.
              </p>
              <p>
                Please note that the furniture used in the photos is for visual
                purposes only. The Villa is unfurnished.
              </p>
            </div>
          </div>
        </Article>
      </section>
    </>
  );
};
