import { Helmet } from "react-helmet";
import { UnderConstruction } from "../../components/UnderConstruction";

export function CurrentDevelopments() {
  return (
    <>
      <Helmet title="Current developments" />
      <UnderConstruction />;
    </>
  );
}
