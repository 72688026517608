import styled from "styled-components";

export const Article = styled.article`
  text-align: left;
  max-width: 1200px;
  margin: 60px 60px;
  @media (max-width: 1200px) {
    margin: 60px 30px;
  }
`;
