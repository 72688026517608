import { useNavigate } from "react-router";
import styled from "styled-components";
import { colours } from "../styles/colours";

const Button = styled.button`
  width: 100%;
  background-color: ${colours.primary};
  padding: 50px 10px;
  font-size: 24px;
  color: white;
  border: none;
  &:hover {
    background-color: ${colours.primaryHover};
    transition: background-color 0.2s ease-in-out;
    cursor: pointer;
  }
  transition: background-color 0.2s ease-in-out;
`;
export const ContactUsBanner = () => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate("/contact-us");
  };
  return <Button onClick={handleOnClick}>Contact us</Button>;
};
