export const colours = {
  primary: "#256370",
  secondary: "#b0b0b0",
  imageOverlay: "#25637079",
  lightGrey: "#ededed",
  background: {
    light: "#efefef",
    dark: "#333333",
  },
  backgroundGradient: "#f8f8f849",
  font: {
    dark: "#333333",
    light: "#ffffff",
  },
  button: {
    mouseOver: "#272727",
  },
  primaryHover: "#272727",
};
