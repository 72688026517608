export const font = {
  title: {
    fontFamily: "Lato-Light",
    fontSize: "60px",
    color: "#FFFFFF",
    fontWeight: 300,
  },
  subtitle: {
    fontFamily: "Lato",
    fontSize: "19px",
    color: "#FFFFFF",
    fontWeight: 300,
  },
};
