import styled from "styled-components";

export const ReviewCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
  max-width: 300px;
`;

export const ReviewBody = styled.p``;

export const ReviewName = styled.span`
  font-weight: bold;
`;

export const ReviewLocation = styled.span`
  color: #999;
`;

interface Review {
  body: string;
  name: string;
  type: string;
  location: string;
}

export const REVIEWS: Review[] = [
  {
    body: "Thank you for your assistance in obtaining a tenant so quickly for our property.",
    name: "Taryn",
    type: "Investor",
    location: "Armando mews",
  },

  {
    body: "I just wanted to thank you so much for being so organised and coming in early this morning. We are both super grateful for all your help.",
    name: "Irene",
    type: "Tenant",
    location: "Finucane Road",
  },

  {
    body: "Thank-you so very much Property Management Team, you have been nothing but a pleasure and great help to us regarding everything.",
    name: "Brian",
    type: "Tenant",
    location: "Armando Mews",
  },
  {
    body: "I couldn’t be happier and would recommend it to anyone looking to move into a smaller home",
    name: "Julie",
    type: "Downsizer",
    location: "Alexandra Hills",
  },
  {
    body: "I would recommend this as a great low cost way to get into the property market and get ahead financially",
    name: "Kate",
    type: "First Home Buyer",
    location: "Alexandra Hills",
  },
  {
    body: "I think paying under $400 rent for a brand new unit is good value and will allow us to save for a good deposit on our own home, in the not too distant future",
    name: "Mitch",
    type: "Tenant",
    location: "Alexandra Hills",
  },
  {
    body: "I had four prospective tenants view the property and achieved a gross return of 6.3%. Overall, I am very impressed with this purchase and intend to make another in the foreseeable future",
    name: "Josh",
    type: "Investor",
    location: "Springwood",
  },
];
