import styled from "styled-components";
import { Anchor } from "./Anchor";
import { Typography } from "./Typography";
import { colours } from "../styles/colours";
import {
  CONTACT_EMAIL,
  CONTACT_PHONE,
  CONTACT_PHONE_FORMATTED,
} from "../constants";

const List = styled.ul`
  color: ${colours.secondary};
  list-style-type: none;
  padding-left: 0;
`;

export default function ContactDetails() {
  return (
    <div>
      <List>
        <li>
          <Typography type="secondary">
            Call us:{" "}
            <Anchor href={`tel:${CONTACT_PHONE}`}>
              {CONTACT_PHONE_FORMATTED}
            </Anchor>
          </Typography>
        </li>
        <li>
          <Typography type="secondary">
            Email us:{" "}
            <Anchor href={`mailto:${CONTACT_EMAIL}`}>{CONTACT_EMAIL}</Anchor>
          </Typography>
        </li>
        <li>
          <Typography type="secondary">PO: 3254, Birkdale QLD</Typography>
        </li>
        <li>
          <Typography type="secondary">Licence Agent #: 4060341</Typography>
        </li>
      </List>
    </div>
  );
}
