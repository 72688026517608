import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  type RouteObject,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import AboutUs from "./routes/about-us";
import ContactUs from "./routes/contact-us/ContactUs";
import MeetTheTeam from "./routes/about-us/meet-the-team/MeetTheTeam";
import { BasePage } from "./components/BasePage";
import { LandingPage } from "./routes/LandingPage/LandingPage";
import { PastDevelopments } from "./routes/past-developments/PastDevelopments";
import { CurrentDevelopments } from "./routes/current-developments/CurrentDevelopments";
import { PropertyManagement } from "./routes/property-management/PropertyManagement";
import { Helmet } from "react-helmet";
import { EdinaStreet } from "./routes/listings/EdinaSt";

export const routes: RouteObject[] = [
  {
    path: "/version",
    element: <span>1.0.1</span>,
  },
  {
    // TODO add typing of the children, so i can ensure that all pages make it into the sitemap/navbar
    path: "/",
    element: <BasePage />,
    handle: "Home",
    children: [
      { index: true, element: <LandingPage /> },
      { path: "*", element: <LandingPage /> }, // TODO; make a better page not found page
      {
        path: "/about-us",
        element: <AboutUs />,
        handle: "About us",
      },
      {
        path: "/meet-the-team",
        element: <MeetTheTeam />,
        handle: "Meet the team",
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
        handle: "Contact us",
      },
      {
        // TODO: nest the developments in a navbar dropdown
        path: "/past-developments",
        element: <PastDevelopments />,
        handle: "Past developments",
      },
      {
        path: "/current-developments",
        element: <CurrentDevelopments />,
        handle: "Current developments",
      },
      {
        path: "/property-management",
        element: <PropertyManagement />,
        handle: "Property management",
      },
      {
        path: "/listings/36-edina-street",
        element: <EdinaStreet />,
        handle: "36/30-46 Edina Street, Thornlands, Qld 4164",
      },
    ],
  },
];

const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <Helmet>
      <title>Arete Real Estate</title>
      <meta
        name="description"
        content="Arete Real Estate marketing and information site"
      />
    </Helmet>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
