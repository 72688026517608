import styled from "styled-components";
import HamburgerMenu from "../HamburgerMenu";
import { colours } from "../../styles/colours";
import logoIcon from "../../media/arete-logo.png";
import logoText from "../../media/areterealestate.webp";
import React from "react";
import { NavLink } from "react-router-dom";

const HamburgerMenuContainer = styled.div``;

const Header = styled.header`
  height: 8vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  nav {
    padding: auto;
    a {
      padding: 8px 0; // TODO: fix this padding, so it actually fills grows to the height of the header
      margin: 0 20px;
      height: 100%;
      font-weight: 600;
      color: ${colours.secondary};
      transition: color 0.2s ease-in-out;
      transition: box-shadow 0.2s ease-in-out;
      text-decoration: none;
      &:hover {
        box-shadow: inset 0 -2px 0 0 ${colours.primary};
        color: ${colours.primaryHover};
        cursor: pointer;
      }
    }
    a.active {
      color: ${colours.primary};
    }
  }
  // hide the Nav for mobile and show the hamburger menu
  @media (max-width: 1200px) {
    nav {
      display: none;
    }
  }
  // hide the hamburger menu for desktop and show the Nav
  @media (min-width: 1201px) {
    ${HamburgerMenuContainer} {
      display: none;
    }
  }
`;
const LogoContainer = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

const LogoText = styled.img`
  height: 27px;
  padding-left: 10px;
  padding-bottom: 4px;
  // hide when on mobile
  @media (max-width: 500px) {
    display: none;
  }
`;

export type LinkType = {
  href: string;
  text: string;
};

// TODO rebuild the navbar to automatically build off of the routes object
export const NavBar = () => {
  const links: LinkType[] = [
    { href: "/", text: "Home" },
    { href: "/about-us", text: "About" },
    { href: "/contact-us", text: "Contact us" },
    { href: "/property-management", text: "Property Management" },
    { href: "/listings/36-edina-street", text: "Listings" },
  ];
  return (
    <Header>
      <LogoContainer>
        <img src={logoIcon} alt="Arete Logo" height={40} />
        <LogoText src={logoText} alt="Arete Real Estate" height={27} />
      </LogoContainer>

      <nav>
        {links.map((link, index) => (
          <NavLink key={index} to={link.href}>
            {link.text}
          </NavLink>
        ))}
      </nav>
      <HamburgerMenuContainer>
        <HamburgerMenu links={links} />
      </HamburgerMenuContainer>
    </Header>
  );
};
