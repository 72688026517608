import React from "react";
import { useNavigate } from "react-router-dom";
interface ButtonProps {
  href: string;
  children: React.ReactNode;
  // props for a button element
  props?: React.ButtonHTMLAttributes<HTMLButtonElement>;
}

export const Button = ({ props, href, children }: ButtonProps) => {
  const navigate = useNavigate();
  const handleOnClick = () => {
    navigate(href);
  };

  return (
    <button {...props} onClick={handleOnClick}>
      {children}
    </button>
  );
};
