import styled from "styled-components";

const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 30px 0;
`;

const Table = styled.table`
  // alternating grey and white rows
  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  // external borders are grey
  border: 1px solid #ddd;
  // internal borders match the row colour
  border-collapse: collapse;
  // padding for the cells
  max-width: 800px;
  td {
    padding: 12px;
  }
  th {
    padding: 12px;
    text-align: left;
  }
`;

export function PolicySection() {
  return (
    <section>
      <h2>PAYMENT & ARREARS POLICY</h2>
      <p>
        Landlords monies are disbursed at the end of each month. Arete Property
        Management provides an itemised statement containing details of all
        rental income and payments made on your behalf.
      </p>
      <TableContainer>
        <Table>
          <caption>PAYMENTS &amp; ARREARS POLICY</caption>
          <tbody>
            <tr>
              <th>TIME ELASPSED</th>
              <th>REMINDER NOTICE</th>
            </tr>
            <tr>
              <td>4 Days in arrears</td>
              <td>
                Courtesy call, sms or reminder letter/email to our customer
              </td>
            </tr>
            <tr>
              <td>8 Days in arrears</td>
              <td>
                RTA Form 11 Notice to Remedy Breach will be served on the tenant
                and a copy forwarded to the lessor for their records. This
                notice allows the tenant 7 days notice to remedy the breach
              </td>
            </tr>
            <tr>
              <td>Expiry of the Form 11</td>
              <td>
                RTA Form 12 Notice to Leave will be served on the tenant to
                terminate the tenancy and a copy forwarded to the lessor for
                their records. The notice period for this form is 7 days
              </td>
            </tr>
            <tr>
              <td>Expiry of the Form 11</td>
              <td>
                Tenant is to handover possession of the property to the Agent.
                Failure to do so may result in the agent lodging an urgent
                tribunal application for termination/warrant of possession
              </td>
            </tr>
          </tbody>
        </Table>
      </TableContainer>
    </section>
  );
}
