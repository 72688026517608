import { colours } from "../styles/colours";
import React from "react";

interface TypographyProps {
  type: "primary" | "secondary";
  children: React.ReactNode;
  size?: number;
}

/** a component which applies the correct font to the children depending on if it is primary or secondary */
export const Typography = ({ type, children, size = 16 }: TypographyProps) => {
  switch (type) {
    case "primary":
      return (
        <span
          style={{
            fontFamily: "Lato, sans-serif",
            color: colours.primary,
            fontSize: size,
          }}
        >
          {children}
        </span>
      );
    case "secondary":
      return (
        <span
          style={{
            fontFamily: "Lato, sans-serif",
            color: colours.secondary,
            fontSize: size,
          }}
        >
          {children}
        </span>
      );
  }
};
