import styled from "styled-components";
import { Article } from "../../components/Article";
import { Button } from "../../components/Button";
import { ImageTitleBanner } from "../../components/ImageTitleBanner";
import { colours } from "../../styles/colours";
import stuartAndKaren from "../../media/stuart-and-karen.jpg";
import { Helmet } from "react-helmet";

const NavButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 1200px;
  margin-bottom: 60px;
  gap: 10px;
  > button {
    margin: 10px;
    max-width: 200px;
    width: 100%;

    height: 67px;
    border: none;
    background-color: ${colours.primary};
    border-radius: 3px;

    font-size: 15px;
    color: white;
    font-weight: 700;
    &:hover {
      cursor: pointer;
    }
  }
`;

export default function AboutUs() {
  return (
    <>
      <Helmet title="About us" />
      <ImageTitleBanner image={stuartAndKaren} title={"About"} />
      <Article>
        <p>
          Arete Real Estate is a recently established, boutique agency located
          in the Redland’s in Brisbane’s Bayside. Arete is focussed on the
          marketing and ongoing management of recently developed properties in
          the Redland’s area and looks to provide an end to end value
          proposition for a wide range of clients from first home buyers to
          experienced investors.
        </p>
        <p>
          “Arete” means virtue and excellence of any kind and this is what Arete
          Real Estate aims to provide for its clients throughout the
          development, sale and rental management processes.
        </p>
        <p>
          Whilst other agencies may look to serve a wide variety of markets,
          sellers and buyers, Arete Real Estate prefers to concentrate on a
          specific product and provide exceptional service to its clients within
          its chosen niche. This is achieved through a unique relationship with
          property developers to provide affordable housing alternatives to both
          owner occupiers and investors in the Redlands.
        </p>
        <p>
          Whether its buying or property management, Arete Real Estate provides
          a genuine alternative to the mainstream real estate agencies in our
          chosen markets.
        </p>
      </Article>
      <NavButtonContainer>
        <Button href="/meet-the-team">Meet the team</Button>
        <Button href="/contact-us">Contact Arete</Button>
      </NavButtonContainer>
    </>
  );
}
