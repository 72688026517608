import styled from "styled-components";
import { Typography } from "./Typography";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #d0d0d0;
  padding: 20px 0;
`;

export const Copyright = () => {
  const currentYear = new Date().getFullYear();
  return (
    <Container>
      <Typography type="secondary" size={12}>
        © {currentYear} Arete Real Estate - All rights reserved.
      </Typography>
    </Container>
  );
};
