import styled from "styled-components";
import { ContactUsBanner } from "../../components/ContactUsBanner";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { colours } from "../../styles/colours";
import jetty from "../../media/wellington-point-jetty86kb20pQual.webp";
import logo from "../../media/areterealestate-large25kb20pQual.webp";
import stuartAndKaren from "../../media/stuart-and-karen.webp";
import pastDevelopmentImage from "../../media/past-development.webp";

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  gap: 50px;
  width: 100%;
`;

const Banner = styled.div`
  position: relative;
  background-image: linear-gradient(#f8f8f849, #f8f8f849), url(${jetty});
  background-size: cover;
  background-position: center;

  width: 100%;
  height: 94vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Links = styled.div`
  // displays the links in row, that wraps when the screen is too small
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1600px;
  gap: 20px;
`;

const LinkButton = styled.button<{ background: string }>`
  background-image: linear-gradient(
      ${colours.imageOverlay},
      ${colours.imageOverlay}
    ),
    url(${(props) => props.background});
  background-size: cover;
  background-position: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  height: 150px;
  width: 200px;
  color: white;
  font-size: 20.8px;
  border: none;

  transition: box-shadow 0.2s ease-in-out;
  &:hover {
    background-image: url(${(props) => props.background});
    cursor: pointer;
  }
  p {
    color: white;
  }
`;

const TitleImage = styled(motion.img)`
  max-width: 400px;
  @media (max-width: 400px) {
    width: 90%;
    object-fit: contain;
  }
`;

export const LandingPage = () => {
  const linkPages = [
    { name: "About Arete", link: "/about-us", image: pastDevelopmentImage },
    {
      name: "Meet the team",
      link: "/meet-the-team",
      image: stuartAndKaren,
    },
    // TODO under construction
    // {
    //   name: "Current developments",
    //   link: "/about-us/current-developments",
    //   image: currentDevelopment,
    // },
    // {
    //   name: "Past developments",
    //   link: "/about-us/past-developments",
    //   image: pastDevelopment,
    // },
  ];
  const navigate = useNavigate();
  const navigateToPage = (link: string) => {
    navigate(link);
  };
  return (
    <Main>
      <Banner>
        <TitleImage
          width={400}
          src={logo}
          alt="Arete Real Estate"
          initial={{
            opacity: 0,
            scale: 0.5,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            duration: 1,
          }}
        />
      </Banner>
      <Links>
        {linkPages.map((linkPage) => (
          <LinkButton
            key={linkPage.link}
            background={linkPage.image}
            onClick={() => navigateToPage(linkPage.link)}
          >
            <p>{linkPage.name}</p>
          </LinkButton>
        ))}
      </Links>
      <ContactUsBanner />
    </Main>
  );
};
