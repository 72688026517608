import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import styled from "styled-components";
import { LinkType } from "./NavBar/NavBar";
import { Link } from "react-router-dom";

const MenuIcon = styled.div`
  width: 30px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
`;

const MenuContainer = styled.div<{ open: boolean }>`
  visibility: ${({ open }) => (open ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 10px;
`;

// when open, the menu is an overlay over the whole screen, that stays there even while scrolling
const Menu = styled(motion.div)`
  display: flex;
  z-index: 3;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  transition: all 0.3s ease-in-out;
  > a,
  button {
    color: white;
    font-size: 2rem;
    text-decoration: none;
    margin: 1rem 0;
    cursor: pointer;
  }
  > button {
    background-color: transparent;
    border: none;
  }
`;

interface HamburgerMenuProps {
  links: LinkType[];
}

const HamburgerMenu = ({ links }: HamburgerMenuProps) => {
  const [open, setOpen] = useState(false);

  const handleMenuClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <MenuContainer onClick={handleMenuClick} open={open}>
        <MenuIcon />
        <MenuIcon />
        <MenuIcon />
      </MenuContainer>
      <AnimatePresence>
        {open && (
          <Menu
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            {links.map((link, index) => (
              <Link to={link.href} key={index} onClick={handleMenuClick}>
                {link.text}
              </Link>
            ))}
            {/* TODO make this an 'x' up the top right rather than an item */}

            <button onClick={handleMenuClick}> Close menu </button>
          </Menu>
        )}
      </AnimatePresence>
    </>
  );
};

export default HamburgerMenu;
