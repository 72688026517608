import styled from "styled-components";
import ContactForm from "./ContactFrom";
import ContactDetails from "../../components/ContactDetails";
import { ImageTitleBanner } from "../../components/ImageTitleBanner";
import wellingtonPoint from "../../media/wellington-point.jpg";
import { Helmet } from "react-helmet";

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
`;

const Container = styled.div`
  max-width: 1200px;
  padding: 50px;
  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  gap: 40px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 40px;
`;

export default function ContactUs() {
  return (
    <>
      <Helmet title="Contact us" />
      <ImageTitleBanner image={wellingtonPoint} title={"Contact us"} />
      <section>
        <Container>
          <Centered>
            <h2>Get in touch</h2>
            <ContactForm />
          </Centered>
          <Centered>
            <h2>Stuart and Karen McDonald</h2>
            <ContactDetails />
          </Centered>
        </Container>
      </section>
    </>
  );
}
