import styled from "styled-components";
import { Article } from "../../../components/Article";
import { ImageTitleBanner } from "../../../components/ImageTitleBanner";
import karen from "../../../media/karen.jpg";
import jetty from "../../../media/wellington-point-jetty86kb20pQual.webp";
import stuart from "../../../media/stuart.jpg";
import amanda from "../../../media/portrait-amanda.jpg";
import { Helmet } from "react-helmet";
const Section = styled.section<{ reverse?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: ${({ reverse }) => (reverse ? "column-reverse" : "column")};
  }
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > img {
    @media (max-width: 400px) {
      width: 100%;
      object-fit: contain;
    }
  }
`;

const Container = styled.div<{ align: string }>`
  max-width: 1200px;
  padding: 0 50px;
  @media (max-width: 1200px) {
    padding: 0 30px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
  text-align: ${({ align }) => align};
  // make the last container have 20px margin on the bottom
  &:last-child {
    margin-bottom: 80px;
  }
`;

export default function MeetTheTeam() {
  return (
    <>
      <Helmet title="Meet the team" />
      <ImageTitleBanner image={jetty} title={"Meet the team"} />
      <Container align="left">
        <Section>
          <Centered>
            <h2>Karen McDonald</h2>
            <img src={karen} alt={"Karen McDonald"} width={400} />
          </Centered>
          <Article>
            <p>
              Karen McDonald is the Director and Principal of Arete Real Estate
              Pty Ltd, a recently established boutique agency situated in the
              Redlands in Brisbane’s beautiful Bayside, focussing on the sale of
              newly constructed property and the management of a growing rental
              portfolio. Karen has been involved in real estate within the
              Redlands for the last ten years as an active partner in her and
              her husband’s property development business, and as a property
              investor. As a resident of the Redlands for over 25 years Karen
              brings local knowledge, a broad range of experience and a very
              unique background to her real estate business.
            </p>

            <p>
              Originally a skilled hairdresser Karen’s career has evolved to
              achieve a bachelor degree in Health Science with a focus on
              complementary medicine, property developer and investor as well as
              a devoted mother of three children. As an active member of the
              community Karen has volunteered with many organisations including
              the Cancer Council and Better Hearing Australia, where she was one
              of BHA’s leading lecturers to the hearing impaired.
            </p>

            <p>
              As an independent real estate operator in a crowded market place
              Karen aims to bring a level of focus and customer service that is
              unique to smaller, owner-operated agencies. Having an end-to-end
              proposition for customers from construction through to sale and
              rental management, Karen is connected to the process from start to
              finish. Having been a customer herself many times Karen has a
              large amount of empathy for what buyers look for in an agent and
              has a strong desire to help her customers through the process of
              acquiring and managing their property successfully.
            </p>

            <p>
              In her spare time Karen enjoys cycling and running as well as
              being addicted to ‘Boot Camp’. A relentless student, Karen is also
              seeking to undertake post graduate studies in diet and nutrition.
            </p>
          </Article>
        </Section>
      </Container>

      <Container align="right">
        <Section reverse>
          <Article>
            <p>
              Stuart has had a long career in finance and property development.
              As Group Executive Business Banking for Suncorp from 2003-2007 he
              ran a variety of businesses including Development Finance and
              Property Investment portfolios that covered an Australia-wide
              portfolio of property projects ranging from small, in-fill
              developments to large, corporate finance projects. Other
              businesses under Stuart’s responsibility included Leasing,
              Agribusiness, Commercial Banking and Corporate Banking. Whilst at
              Suncorp Stuart was also Group Executive Strategy, People and
              Corporate Development, Chairman of L.J. Hooker Pty Ltd and a
              Director of RACQ Insurance Pty Ltd.
            </p>

            <p>
              After leaving Suncorp Stuart spent two years as Chief Executive
              Officer and Managing Director of The Rock Building Society,
              culminating in its successful sale to MyState Financial in 2012.
            </p>

            <p>
              Personal property development and real estate projects began in
              2010 and have now increased to be Stuart’s major focus.
            </p>

            <p>
              Stuart has a Bachelor of Commerce from the University of
              Queensland, a Master’s of Applied Finance from Macquarie
              University focusing on financial risk management, and has
              successfully completed the Harvard Executive Management Programme.
              Stuart was also a Visiting Fellow in the Centre for Money, Banking
              and Finance 2000-2003.
            </p>
          </Article>
          <Centered>
            <h2>Stuart McDonald</h2>
            <img src={stuart} alt={"Stuart McDonald"} width={400} />
          </Centered>
        </Section>
      </Container>

      <Container align="left">
        <Section>
          <Centered>
            <h2>Amanda</h2>
            <img src={amanda} alt={"Amanda"} width={400} />
          </Centered>
          <Article>
            <p>
              For the past 20 years, Amanda has worked in the property,
              construction, real estate and legal industries. This has provided
              her a vast range of skills and experience that she draws on for
              every client, whilst delivering a unique and individually tailored
              experience.
            </p>
            <p>
              Every landlord is different, therefore every landlords process and
              experience should be special. She also offers a breath of
              experience and acknowledge in consulting on residential
              properties.
            </p>
          </Article>
        </Section>
      </Container>
    </>
  );
}
