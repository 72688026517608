import { NavBar } from "./NavBar/NavBar";
import { Footer } from "./Footer/Footer";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const Article = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BasePage = () => {
  return (
    <Container>
      <div>
        <NavBar />
        <Article>
          <Outlet />
        </Article>
      </div>
      <Footer />
    </Container>
  );
};
